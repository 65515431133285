import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as actions from "./redux/actions/index";
import React, { Component } from "react";
import { Route } from "react-router-dom";

const Copyright = React.lazy(() => import("./common/Copyright"));
const Header = React.lazy(() => import("./common/header"));
const Home = React.lazy(() => import("./pages/HomeComponent/home"));
const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));
const ForgotPassword = React.lazy(() => import("./pages/ForgotPassword"));
const SubCategory = React.lazy(() => import("./pages/SubCategory"));
const ProductList = React.lazy(() => import("./pages/ProductListing"));
const MessageDialog = React.lazy(() => import("./common/MessageDialog"));
const Cart = React.lazy(() => import("./pages/Cart"));
const ProductDescription = React.lazy(() => import("./pages/ProductDescription"));
const OrderHistory = React.lazy(() => import("./pages/OrderHistory"));
const OrderDetails = React.lazy(() => import("./pages/OrderDetails"));
const ThankYou = React.lazy(() => import("./pages/OrderDetails/thankYou"));
const WishList = React.lazy(() => import("./pages/WishList"));
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const ContactUs = React.lazy(() => import("./pages/ContactUs"));
const Account = React.lazy(() => import("./pages/MyAccount"));
const Password = React.lazy(() => import("./pages/MyAccount/password"));
class AppRoutes extends Component {
    render() {

        if (sessionStorage.getItem('reloaded') !== null) {
        } else {
            if (this.props.isLogin) {
                this.props.logout();
                return (
                    <>
                        <MessageDialog />
                        <div className="app-container">
                            <Login ></Login>
                        </div>
                    </>
                )
            }
            sessionStorage.setItem('reloaded', 'yes');
        }


        const isNotLogin = window.location.pathname.includes('sign-in') === false &&
            window.location.pathname.includes('sign-up') === false &&
            window.location.pathname.includes('forgot-password') === false

        let isHomePage = window.location.pathname.includes('/home');

        // added /sub-cat/:name to handle refresh
        return (
            <>
                <Header />
                <MessageDialog />
                <div className={(isNotLogin ? (isHomePage ? "app-container-home" : "app-container") : "")}>
                    <Route path="/about-us" component={AboutUs} />
                    <Route path="/contact-us" component={ContactUs} />
                    <Route path="/wishlist" component={WishList} />
                    <Route path="/cart" component={Cart} />
                    <Route path="/product" component={ProductDescription} />
                    <Route path="/products/:category" exact component={ProductList} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/sign-up" component={Register} />
                    <Route path="/sign-in" component={Login} />
                    <Route path="/home" component={Home} />
                    <Route path="/sub-cat/:name" exact component={SubCategory} />
                    <Route path="/my-account" component={Account} />
                    <Route path="/my-password" component={Password} />
                    <Route path="/orders-history" component={OrderHistory} />
                    <Route path="/order" component={OrderDetails} />
                    <Route path="/thank-you" component={ThankYou} />
                    <Route exact path="/" component={Login} />
                </div>
                <Copyright />
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLogin: state.loginDetails.isUserLoggedIn,
        subCategory: state.category.subCategory,
        loader: state.category.loader,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(actions.logout()),
        getCategory: (payload) => dispatch(actions.getCategory(payload)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(injectIntl(AppRoutes)));
