import "bootstrap/dist/css/bootstrap.css";
import React, { Component } from "react";
import Loader from '../loader';
import ImageLoader from '../../assets/loader.gif';
 
class WelcomePage extends Component {
 
  render() {
    return (<>
    <div className="welcome-page" >
        <div className="welcome-logo">
            <Loader />
            <img src={ImageLoader} className="loader-image" alt="loader" />
        </div>
    </div>
        </>
    );
  }
}

export default WelcomePage;
