import { API } from "../../api/api";
import * as actionType from "../actionTypes";
import { store } from "../store/store";
import fire from '../../config/firebase';
import { callActionLoginUser, callActionOnShowPopUp, getToken, OnLogoutUser } from './signUp';
import { getCartData } from './cart';
import firebase from 'firebase';

const callActionGetProductList = (payload) => {
    return {
        type: actionType.GET_PRODUCTS_LIST,
        payload: payload,
    };
};

export const removeFilter = () => {
    return (dispatch) => {
        dispatch(callActionGetProductList({appliedFilter: {} }));
    }
}

//get product action
export const getProductList = (payload) => {
    return (dispatch) => {
        let data = payload;
        if( Object.keys(data.filters).length === 0){
            delete data.filters
        }
        dispatch(callActionGetProductList({ plploader: true, productList:[], filters:[] }));

        let cb = {
            success: (res) => {

                if (res.code === 200 || res.code === 201) {
                    // firebase.analytics().logEvent('product_list', {'category_name': 'alpine-dressMaterial-root'});
                    let data = {
                            filters: res.result[0],
                            productList: res.result[1],
                            plploader: false,
                            appliedFilter: payload.filters || {} ,
                            plpcat: payload.category ? payload.category : payload.hotDeals ? "hotDeals" : payload.newArrivals ? "newArrivals" : ""
                        }
                    dispatch(callActionGetProductList(data));
                    //                            dispatch(callActionOnShowPopUp({ dialogMessage: res.message }));
                }
            },
            error: (err) => {
                if (err.code === 401) {
                    dispatch(getToken());
                }

                else if (err.code === 402) {
                    dispatch(OnLogoutUser());
                    if (!window.location.pathname.includes('/sign-in')) {
                        dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
                        window.location.replace('/sign-in')
                    }
                }
                else{
                    dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please reload the page." }));
                }

                let data = {
                    plploader: false
                }
                dispatch(callActionGetProductList(data));
                dispatch(getToken());
            },
        };

        API.getProductList(data, cb);
    };
};

const callActionGetProductData = (payload) => {
    return {
        type: actionType.GET_PRODUCT_DATA,
        payload: payload,
    };
};

// get product data action
// added store location and passed to API
export const getProductData = (payload) => {
    return (dispatch, getState) => {
        let data = payload;
        dispatch(callActionGetProductData({ loader: true, product:{} }));
        let storeLocation =  getState().loginDetails.storeLocation;

        let cb = {
            success: (res) => {
                if (res.code === 200 || res.code === 201) {
                    let event_data = { ...res.result, 
                        phoneNumber: getState().loginDetails.customer_details.phoneNumber,
                        customerName: getState().loginDetails.customer_details.customerName }
                    firebase.analytics().logEvent('product_description', event_data);
                    let data = {
                        product: res.result,
                        loader: false
                    }
                    dispatch(callActionGetProductData(data));
                    //                            dispatch(callActionOnShowPopUp({ dialogMessage: res.message }));
                }
            },
            error: (err) => {
                if (err.code === 401) {
                    dispatch(getToken());
                }

                else if (err.code === 402) {
                    dispatch(OnLogoutUser());
                    if (!window.location.pathname.includes('/sign-in')) {
                        dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
                        window.location.replace('/sign-in')
                    }
                }
                else{
                    dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please reload the page." }));
                }

                let data = {
                    loader: false
                }
                dispatch(callActionGetProductData(data));
                dispatch(getToken());
            },
        };

        API.getProductData(storeLocation, data, cb);
    };
};

const callActionAddToCart = (payload) => {
    return {
        type: actionType.ADD_TO_CART,
        payload: payload,
    };
};

export const falseLoader = () => {
    return (dispatch) => {
        dispatch(callActionAddToCart({ addToCartLoader: false }));
    }
}

// add product to cart action
export const addToCart = (payload) => {
    return (dispatch, getState) => {
        let data = payload;
        data.designNumber = payload && payload.cartListItem && payload.cartListItem.designNumber;
        data.itemId = '1234';
        dispatch(callActionAddToCart({ addToCartLoader: true }));
        let phone = getState().loginDetails && getState().loginDetails.customer_details && getState().loginDetails.customer_details.phoneNumber;
        // added store location and passed to API
        let storeLocation = getState().loginDetails.storeLocation;
        
        let cb = {
            success: (res) => {
                if (res.code === 200 || res.code === 201) {
                   // firebase.analytics().logEvent('add_to_cart', data);
                   
                    dispatch(getCartData({
                        id: getState().loginDetails.customer_details.phoneNumber,
                        itemCount: true,
                        
                    }));
                    dispatch(getWishList());
                    let data = {
                        addToCartLoader: false
                    }
                    dispatch(callActionAddToCart(data));
                    dispatch(callActionOnShowPopUp({ dialogMessage: res.message, dialogTimer: true }));
                }
            },
            error: (err) => {
                if (err.code === 401) {
                    dispatch(getToken());
                }

                else if (err.code === 402) {
                    dispatch(OnLogoutUser());
                    if (!window.location.pathname.includes('/sign-in')) {
                        dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
                        window.location.replace('/sign-in')
                    }
                }
                else{
                    dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please try again.", dialogTimer: true }));
                }

                let data = {
                    addToCartLoader: false
                }
                dispatch(callActionAddToCart(data));
            },
        };

        API.addToCart(storeLocation, phone, data, cb);
    };
};


export const addToWishList = (payload) => {
    return (dispatch, getState) => {
        let data = payload;
        dispatch(callActionAddToCart({ addToCartLoader: true }));
        let phone = getState().loginDetails && getState().loginDetails.customer_details && getState().loginDetails.customer_details.phoneNumber;
        // added store location and passed to API
        let storeLocation = getState().loginDetails.storeLocation;

        let cb = {
            success: (res) => {
                if (res.code === 200 || res.code === 201) {
                    let data = {
                        addToCartLoader: false
                    }
                    dispatch(callActionAddToCart(data));
                    dispatch(callActionOnShowPopUp({ dialogMessage: res.message, dialogTimer: true }));
                }
            },
            error: (err) => {
                if (err.code === 401) {
                    dispatch(getToken());
                }

                else if (err.code === 402) {
                    dispatch(OnLogoutUser());
                    if (!window.location.pathname.includes('/sign-in')) {
                        dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
                        window.location.replace('/sign-in')
                    }
                }

                let data = {
                    addToCartLoader: false
                }
                dispatch(callActionAddToCart(data));
                dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please try again.", dialogTimer: true }));
            },
        };

        API.addToWishList(storeLocation, phone, data, cb);
    };
};

//get wishlist item action
export const getWishList = () => {
    return (dispatch, getState) => {
        let phone = getState().loginDetails && getState().loginDetails.customer_details && getState().loginDetails.customer_details.phoneNumber;
        // added store location and passed to API
        let storeLocation = getState().loginDetails.storeLocation;

        dispatch(callActionAddToCart({ loader: true }));
        let cb = {
            success: (res) => {
                if (res.code === 200 || res.code === 201) {
                    let data = {
                        wishList: res.result,
                        loader: false
                    }
                    dispatch(callActionAddToCart(data));
                }
            },
            error: (err) => {
                dispatch(callActionAddToCart({ loader: false }));
                if (err.code === 401) {
                    dispatch(getToken());
                }
                else if (err.code === 402) {
                    dispatch(OnLogoutUser());
                    if (!window.location.pathname.includes('/sign-in')) {
                        dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
                        window.location.replace('/sign-in')
                    }
                }
                else {

                    dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please try again." }));
                }
            },
        };

        API.getWishList(storeLocation, phone, cb);
    };
};

//delete wishlist item action
export const deleteWishListItem = (payload) => {
    return (dispatch, getState) => {
        let data = payload;
        dispatch(callActionAddToCart({ loader: true }));
        let phone = getState().loginDetails && getState().loginDetails.customer_details && getState().loginDetails.customer_details.phoneNumber;
        // added store location and passed to API
        let storeLocation = getState().loginDetails.storeLocation;

        let cb = {
            success: (res) => {
                dispatch(callActionAddToCart({ loader: false }));
                dispatch(getWishList());
            },
            error: (err) => {
                dispatch(callActionAddToCart({ loader: false }));
                if (err.code === 401) {
                    dispatch(getToken());
                }
                else if (err.code === 402) {
                    dispatch(OnLogoutUser());
                    if (!window.location.pathname.includes('/sign-in')) {
                        dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
                        window.location.replace('/sign-in')
                    }
                }
            },
        };

        API.deleteWishListItem(storeLocation, phone, data, cb);
    };
};
