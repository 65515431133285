import { API } from "../../api/api";
import * as actionType from "../actionTypes";
import firebaseInstance from '../../config/firebase';
import firebase from 'firebase';
import { removeFilter } from './products';

const Cryptr = require('cryptr');
const cryptr = new Cryptr(process.env.REACT_APP_API_ENCRYPTKEY);
export const login = (payload) => {
  return (dispatch, getState) => {
    dispatch(callActionLoginUser({ loading: true }));
    setTimeout(() => {
      let data = { ...payload.req, password: cryptr.encrypt(payload.req.password) };
      let cb = {
        success: (res) => {
          if (res.code === 200) {
            if (res.result && res.result.token) {
              firebase.analytics().logEvent('sign_in', res.result);
              firebaseInstance.auth().signInWithCustomToken(res.result.token)
                .then((userCredential) => {
                  // Signed in
                  let data = {
                    customer_details: res.result,
                    isUserLoggedIn: true,
                    loginMessage: res.message,
                    loginErrorMessage: null,
                    errorMessage: null,
                    loading: false,
                    storeLocation: res.result.location[0],
                    logInApiToken: res.result.token,
                    idToken: userCredential.user && userCredential.user.Aa,
                    refreshToken: userCredential && userCredential.user && userCredential.user.refreshToken,

                  };
                  dispatch(callActionLoginUser(data));
                  console.log("inside login action");
                  window.location.replace('/home')
                })
                .catch((error) => {
                  let data = {
                    customer_details: {},
                    isUserLoggedIn: false,
                    loginMessage: '',
                    loginErrorMessage: error.message,
                    errorMessage: error.message,
                    loading: false,
                    idToken: ''
                  }
                  dispatch(callActionOnShowPopUp({ dialogMessage: error.message }));

                  dispatch(callActionLoginUser(data));
                  // ...
                });
            }
            else {
              dispatch(callActionOnShowPopUp({ dialogMessage: res.message }));
              dispatch(callActionLoginUser({ loading: false }));
            }
            // toast(res.message)
          }
          dispatch(removeFilter());
        },
        error: (err) => {
          let data = {
            customer_details: {},
            isUserLoggedIn: false,
            loginMessage: '',
            loginErrorMessage: err.message,
            errorMessage: err.message,
            loading: false,
            idToken: ''
          };
          dispatch(callActionOnShowPopUp({ dialogMessage: err.message }));
          dispatch(callActionLoginUser({ ...data }));
          dispatch(removeFilter());
        },
      };

      API.loginUser(data, cb);
    })
  };
};

export const callActionLoginUser = (payload) => {
  return {
    type: actionType.LOGIN,
    payload: payload,
  };
};


export const LoginLoadingFalse = () => {
  return (dispatch) => {
    dispatch(callActionRegisterUser({ loading: false }));
  };
};

const callActionRegisterUser = (payload) => {
  return {
    type: actionType.REGISTER,
    payload: payload,
  };
};

export const register = (payload) => {
  return (dispatch) => {
    dispatch(callActionRegisterUser({ loading: true }));

    setTimeout(() => {
      let data = { ...payload, password: cryptr.encrypt(payload.password) };

      let cb = {
        success: (res) => {

          if (res.code === 200 || res.code === 201) {
            let data = {
              registerMessage: res.message,
              registerErrorMessage: null,
              registerDetails: res.result,
              loading: false
            }
            dispatch(callActionRegisterUser(data));
            dispatch(callActionOnShowPopUp({ dialogMessage: res.message }));
          }
        },
        error: (err) => {

          let data = {
            registerMessage: null,
            registerErrorMessage: err.message,
            registerDetails: {},
            loading: false
          }
          dispatch(callActionRegisterUser(data));
          dispatch(callActionOnShowPopUp({ dialogMessage: err.message }));
        },
      };

      API.registerUser(data, cb);
    });
  };
};

const callActionGetOtp = (payload) => {
  return {
    type: actionType.GET_OTP,
    payload: payload,
  };
};

export const getOtp = (payload) => {
  return (dispatch) => {
    let payloadData = payload;
    dispatch(callActionGetOtp({ loading: true }));

    console.log("trial version is ", process.env.REACT_APP_TRIAL_VERSION);
    if (process.env.REACT_APP_TRIAL_VERSION === 'false') {
      let cb = {
        success: (res) => {
          if (res.code === 200 || res.code === 201) {
            let data = {
              otpFetched: true,
              loading: false,
              otpRecTimeMilli: Date.now(),
              otpPayload: payload
            }

            setTimeout(() => {
              dispatch(deleteOtp(payloadData));
              let data = {
                otpFetched: false,
                loading: false,
                otpRecTimeMilli: null,
                otpPayload: null
              }
              dispatch(callActionGetOtp(data));
              dispatch(callActionOnShowPopUp({ dialogMessage: "Your otp is expired!!" }));
            }, 600000);
            dispatch(callActionGetOtp(data));
            dispatch(callActionOnShowPopUp({ dialogMessage: res.message }));
          }
        },
        error: (err) => {

          let data = {
            otpFetched: false,
            loading: false
          }
          dispatch(callActionGetOtp(data));
          dispatch(callActionOnShowPopUp({ dialogMessage: err.message }));
        },
      };

      API.getOtp(payloadData, cb);
    } else {

      let data = {
        otpFetched: true,
        loading: false,
        otpRecTimeMilli: Date.now(),
        otpPayload: payload
      }
      dispatch(callActionGetOtp(data));
      dispatch(callActionOnShowPopUp({ dialogMessage: "Otp Generated Successfully" }));
    }
  }
};

export const deleteOtp = (payload) => {
  return (dispatch) => {
    let data = payload;
    // dispatch(callActionGetOtp({ loading: true }));

    let cb = {
      success: (res) => {
        if (res.code === 200 || res.code === 201) {
          let data = {
            otpFetched: false,
            loading: false,
            otpRecTimeMilli: null,
            otpPayload: null
          }

          dispatch(callActionGetOtp(data));
          //  dispatch(callActionOnShowPopUp({ dialogMessage: "Your otp is expired!!" }));
        }
      },
      error: (err) => {

        // let data = {
        //   otpFetched: false,
        //   loading: false
        // }
        // dispatch(callActionGetOtp(data));
        // dispatch(callActionOnShowPopUp({ dialogMessage: err.message }));
      },
    };

    API.deleteOtp(data, cb);
  };
};


const callActionChangePassword = (payload) => {
  return {
    type: actionType.CHANGE_PASSWORD,
    payload: payload,
  };
};


export const changePassword = (payload) => {
  return (dispatch) => {
    let data = {
      password: cryptr.encrypt(payload.password),
      otp: payload.otp,
      trialVersion: payload.trialVersion
    };
    dispatch(callActionChangePassword({ loading: true }));

    let phone = payload.phoneNum;

    let cb = {
      success: (res) => {

        if (res.code === 200 || res.code === 201) {
          let data = {
            loading: false
          }
          dispatch(callActionChangePassword(data));
          dispatch(callActionOnShowPopUp({ dialogMessage: res.message }));
          window.location.replace('/login')
        }
      },
      error: (err) => {

        let data = {
          loading: false,
        }
        dispatch(callActionChangePassword(data));
        dispatch(callActionOnShowPopUp({ dialogMessage: err.message }));
      },
    };

    API.changePassword(phone, data, cb);
  };
};


export const OnLogoutUser = () => {
  return {
    type: actionType.LOGOUT,
  };
};

export const logout = () => {
  return (dispatch, getState) => {
    if (firebaseInstance.auth().currentUser) {
      firebaseInstance.auth().currentUser.delete().then(() => {
        dispatch(OnLogoutUser());
        if (!window.location.pathname.includes('/sign-in')) {
          window.location.replace('/sign-in')
        }
        // Sign-out successful.
      }).catch((error) => {
        // An error happened.
      });

      dispatch(callActionChangePassword({ loading: false }));
    } else {
      dispatch(callActionChangePassword({ loading: true }));
      firebaseInstance.auth().signInWithCustomToken(getState().loginDetails.logInApiToken)
        .then((userCredential) => {
          if (firebaseInstance.auth().currentUser) {
            firebaseInstance.auth().currentUser.delete().then(() => {
              dispatch(OnLogoutUser());
              if (!window.location.pathname.includes('/sign-in')) {
                console.log(window.location, window.location.pathname, window.location.pathname.includes('/sign-in'))
                window.location.replace('/sign-in')
              }
              // Sign-out successful.
            }).catch((error) => {
              // An error happened.
            });

            dispatch(callActionChangePassword({ loading: false }));
          } else {
            firebaseInstance.auth().signOut().then(() => {
              dispatch(OnLogoutUser());
              if (!window.location.pathname.includes('/sign-in')) {
                console.log(window.location, window.location.pathname, window.location.pathname.includes('/sign-in'))
                window.location.replace('/sign-in')
              }

              // Sign-out successful.
            }).catch((error) => {
              // An error happened.
            });

            dispatch(callActionChangePassword({ loading: false }));
          }
        })
        .catch((error) => {
          firebaseInstance.auth().signOut().then(() => {
            dispatch(OnLogoutUser());
            if (!window.location.pathname.includes('/sign-in')) {
              console.log(window.location, window.location.pathname, window.location.pathname.includes('/sign-in'))
              window.location.replace('/sign-in')
            }

            // Sign-out successful.
          }).catch((error) => {
            // An error happened.
          });
          dispatch(callActionChangePassword({ loading: false }));
        });
    }
  };
};

const callActionOnHideOtp = (payload) => {
  return {
    type: actionType.HIDE_OTP,
    payload: payload,
  };
};

export const hideOtp = () => {
  return (dispatch) => {
    dispatch(callActionOnHideOtp());
  };
};

export const loginAfterExpire = (payload) => {
  return (dispatch) => {
    let data = payload
    dispatch(callActionLoginUser({ loading: true }));
    let cb = {
      success: (res) => {
        if (res.code === 200) {
          if (res.result && res.result.token) {
            firebaseInstance.auth().signInWithCustomToken(res.result.token)
              .then((userCredential) => {
                // Signed in
                let data = {
                  customer_details: res.result,
                  isUserLoggedIn: true,
                  loginMessage: res.message,
                  loginErrorMessage: null,
                  errorMessage: null,
                  loading: false,
                  logInApiToken: res.result.token,
                  idToken: userCredential.user && userCredential.user.Aa,
                  refreshToken: userCredential && userCredential.user && userCredential.user.refreshToken
                };
                dispatch(callActionLoginUser(data));
              })
              .catch((error) => {
                let data = {
                  customer_details: {},
                  isUserLoggedIn: false,
                  loginMessage: '',
                  loginErrorMessage: error.message,
                  errorMessage: error.message,
                  loading: false,
                  idToken: ''
                }
                //  toast.error(error.message)

                dispatch(callActionLoginUser(data));
                // ...
              });
          }
          // toast.info(res.message)
        }
      },
      error: (err) => {
        let data = {
          customer_details: {},
          isUserLoggedIn: false,
          loginMessage: '',
          loginErrorMessage: err.message,
          errorMessage: err.message,
          loading: false,
          idToken: ''
        };
        // toast.error(err.message)
        dispatch(callActionLoginUser({ ...data }));
      },
    };

    API.loginUser(data, cb);
  };
};

export const getToken = () => {
  return (dispatch, getState) => {
    dispatch(callActionLoginUser({ loading: true }));
    if (firebaseInstance.auth().currentUser) {
      firebaseInstance.auth().currentUser.getIdToken(true)
        .then(token => {
          let data = {
            ...getState().loginDetails,
            logInApiToken: token,
            idToken: token,
            loading: false
          };
          dispatch(callActionLoginUser(data));
        }).catch(error => {
          // toast.error(error.messase)
          dispatch(callActionLoginUser({ loading: false }));
        });
    } else {
      firebaseInstance.auth().onAuthStateChanged(user => {
        if (user != null) {
          user.getIdToken(true)
            .then(token => {
              let data = {
                ...getState().loginDetails,
                logInApiToken: token,
                idToken: token,
                loading: false
              };
              dispatch(callActionLoginUser(data));
            }).catch(error => {
              // toast.error(error.messase)
              dispatch(callActionLoginUser({ loading: false }));
            });
        } else {
          dispatch(callActionLoginUser({ loading: false }));
        }
      });
    }
  };
}

export const callActionOnShowPopUp = (payload) => {
  return {
    type: actionType.SHOW_POPUP,
    payload: payload,
  };
};

export const showPopup = (payload) => {
  return (dispatch) => {
    dispatch(callActionOnShowPopUp(payload));
  };
};

const callActionOnClosePopUp = (payload) => {
  return {
    type: actionType.CLOSE_POPUP,
    payload: payload,
  };
};

export const hidePopup = () => {
  return (dispatch) => {
    dispatch(callActionOnClosePopUp());
  };
};


const callActionUpdateCustomer = (payload) => {
  return {
    type: actionType.UPDATE_CUSTOMER,
    payload: payload,
  };
};


export const updateCustomer = (payload) => {
  return (dispatch, getState) => {
    dispatch(callActionUpdateCustomer({ loading: true }));
    let data = payload.reqData;
    let phone = payload.phoneNum;
    let password = cryptr.encrypt(payload.password);

    let cb = {
      success: (res) => {

        if (res.code === 200 || res.code === 201) {
          let user = getState().loginDetails.customer_details
          let newUser = { ...user, ...payload.reqData }
          let data = {
            customer_details: newUser,
            loading: false
          }
          dispatch(callActionUpdateCustomer(data));
          dispatch(callActionOnShowPopUp({ dialogMessage: res.message }));
        }
      },
      error: (err) => {

        if (err.code === 401) {
          dispatch(getToken());
        }
        else if (err.code === 402) {
          dispatch(OnLogoutUser());
          if (!window.location.pathname.includes('/sign-in')) {
            dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
            window.location.replace('/sign-in')
          }
        }

        let data = {
          loading: false,
        }
        dispatch(callActionUpdateCustomer(data));
        dispatch(callActionOnShowPopUp({ dialogMessage: err.message }));
      },
    };

    API.updateCustomer(phone, password, data, cb);
  };
};


export const updatePassword = (payload) => {
  return (dispatch) => {
    dispatch(callActionUpdateCustomer({ loading: true }));
    let phone = payload.phoneNum;
    let password = cryptr.encrypt(payload.currentPassword);
    let newPassword = cryptr.encrypt(payload.newPassword);
    let data = {
      oldPassword: password,
      newPassword: newPassword
    };

    let cb = {
      success: (res) => {

        if (res.code === 200 || res.code === 201) {
          let data = {
            loading: false
          }
          dispatch(callActionUpdateCustomer(data));
          dispatch(callActionOnShowPopUp({ dialogMessage: res.message }));
          window.location.replace('/sign-in')
        }
      },
      error: (err) => {
        if (err.code === 401) {
          dispatch(getToken());
        }
        else if (err.code === 402) {
          dispatch(OnLogoutUser());
          if (!window.location.pathname.includes('/sign-in')) {
            dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
            window.location.replace('/sign-in')
          }
        }

        let data = {
          loading: false,
        }
        dispatch(callActionUpdateCustomer(data));
        dispatch(callActionOnShowPopUp({ dialogMessage: err.message }));
      },
    };

    API.updatePassword(phone, data, cb);
  };
};

export const removePleaseWait = () => {
  return (dispatch) => {
    dispatch(callActionUpdateCustomer({ loading: false }));
  }
}

export const changeLocation = (payload) => {
  return (dispatch) => {
    dispatch(callActionLoginUser(payload));
  };
};

// added this function to get store names
const callActionGetStoreNames = (payload) => {
  return {
    type: actionType.GET_STORE_NAMES,
    payload: payload,
  };
};

// added this export function to get store names
export const getStoreNamesData = (payload) => {
  return (dispatch, getState) => {
    let data = payload;
    dispatch(callActionGetStoreNames({ loader: true }));

    let cb = {
      success: (res) => {
        if (res.code === 200 || res.code === 201) {

          let data = {
            storeNames: res.result,
          }
          dispatch(callActionGetStoreNames(data));

          // toast.success(res.message)

          setTimeout(() => {
            dispatch(callActionGetStoreNames({ loader: false }));
          }, 400);
        }
      },
      error: (err) => {

        dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please reload the page." }));

        let data = {
          loader: false
        }
        dispatch(callActionGetStoreNames(data));
      },
    };

    API.getStoreNames(data, cb);
  };
};


// added this function to get About Us details
const callActionGetAboutUs = (payload) => {
  return {
    type: actionType.GET_ABOUT_US,
    payload: payload,
  };
};

// added this export function to get About Us details
export const getAboutUsInfo = (payload) => {
  return (dispatch, getState) => {
    let data = payload;
    let storeLocation = getState().loginDetails.storeLocation;
    dispatch(callActionGetAboutUs({ loader: true }));

    let cb = {
      success: (res) => {
        if (res.code === 200 || res.code === 201) {

          let data = {
            aboutUsInfo: res.result,
            loader: false
          }
          dispatch(callActionGetAboutUs(data));

          // toast.success(res.message)

          setTimeout(() => {
            dispatch(callActionGetAboutUs({ loader: false }));
          }, 400);
        }
      },
      error: (err) => {

        if (err.code === 500)
          dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please reload the page." }));

        let data = {
          aboutUsInfo: null,
          loader: false
        }
        dispatch(callActionGetAboutUs(data));
      },
    };

    API.getAboutUs(storeLocation, data, cb);
  };
};