// import { API_TOKEN } from "./globalApi";
import { store } from "../redux/store/store";

export const buildHeader = (headerParams = {}) => {
  let IdToken = store.getState() && store.getState().loginDetails && store.getState().loginDetails.idToken;
  
  var header = {
    Authorization: IdToken,
    // Accept: "application/json",
    // "Content-Type": "application/json; charset=utf-8",
    // "Cache-Control": "no-cache",
    "Access-control-allow-origin": "*",
  };
  Object.assign(header, headerParams);
  return header;
};


// export const ipInfoHeader = (headerParams = {}) => {
//   var header = {
//     Authorization: `Bearer ${IP_INFO_TOKEN}`,
//     Accept: "application/json",
//     "Content-Type": "application/json; charset=utf-8",
//     "Cache-Control": "no-cache",
//     "Access-control-allow-origin": "*",
//   };
//   Object.assign(header, headerParams);
//   return header;
// };
