import firebase from 'firebase';
const firebaseConfig = {
    apiKey: "AIzaSyAvFhdpSInQCbXwazuZfHFPvkG7AnGfWDE",
    authDomain: "karma-prints.firebaseapp.com",
    projectId: "karma-prints",
    storageBucket: "karma-prints.appspot.com",
    messagingSenderId: "755536237570",
    appId: "1:755536237570:web:eaff2defcf49678712c31b",
    measurementId: "G-DJ8YZ2LXP9"
  };

const fire = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();

// firebase.auth().useEmulator('http://localhost:9099/');

export default fire;

