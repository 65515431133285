export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER";
export const LOADING_SPINNER = "LOADING_SPINNER";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_BANNER = "GET_CATEGORY_BANNER";
export const GET_OTP = "GET_OTP";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const LOGOUT = "LOGOUT";
export const HIDE_OTP = "HIDE_OTP";
export const GET_PRODUCTS_LIST = "GET_PRODUCTS_LIST";
export const GET_CART = "GET_CART";
export const UPDATE_ITEM_QTY = "UPDATE_ITEM_QTY";
export const GET_PRODUCT_DATA = "GET_PRODUCT_DATA";
export const ADD_TO_CART = "ADD_TO_CART";
export const SHOW_POPUP = "SHOW_POPUP";
export const CLOSE_POPUP = "CLOSE_POPUP";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const GET_ORDER_LIST = "GET_ORDER_LIST";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
//added constant GET_STORE_NAMES
export const GET_STORE_NAMES = "GET_STORE_NAMES";
// added constant GET_ABOUT_US
export const GET_ABOUT_US = "GET_ABOUT_US";