import { API } from "../../api/api";
import * as actionType from "../actionTypes";
import { callActionOnShowPopUp, getToken, OnLogoutUser } from './signUp';
import firebase from 'firebase';

const callActionGetCart = (payload) => {
  return {
    type: actionType.GET_CART,
    payload: payload,
  };
};

export const getCartData = (payload) => {
  return (dispatch, getState) => {
    let data = payload;
    // added store location and passed to API
    let storeLocation = getState().loginDetails.storeLocation;

    dispatch(callActionGetCart({ loader: true }));
    

    let cb = {
      success: (res) => {
        if (res.code === 200 || res.code === 201) {
          if (payload && payload.itemCount) {
            let data = {
              cart: getState().cart.cart,
              cartCount: res.result,
              // loader: false
            }
            dispatch(callActionGetCart(data));

          } else {
            let data = {
              cartCount: getState().cart.cartCount,
              cart: res.result,
              // loader: false
            }
            dispatch(callActionGetCart(data));
          }

          // toast.success(res.message)

          setTimeout(() => {
            dispatch(callActionGetCart({loader: false}));
          }, 400);
        }
      },
      error: (err) => {
        if (err.code === 401) {
          dispatch(getToken());
        }
        else if (err.code === 402) {
          dispatch(OnLogoutUser());
          if (!window.location.pathname.includes('/sign-in')) {
            dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
            window.location.replace('/sign-in')
          }
        }
        else{
          dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please reload the page." }));
        }
        let data = {
          loader: false
        }
        dispatch(callActionGetCart(data));
        dispatch(getToken());
      },
    };

    API.getCart(storeLocation, data, cb);
  };
};

export const updateCartQyt = (payload) => {
  return (dispatch, getState) => {
    let data = payload;
    let cartdData = getState().cart && getState().cart.cart;
    // added store location and passed to API
    let storeLocation = getState().loginDetails.storeLocation;

    dispatch(callActionGetCart({ loader: true }));

    let cb = {
      success: (res) => {
        if (res.code === 200 || res.code === 201) {
          cartdData.forEach(ele => {
            if (ele.designNumber === payload.cartListItem.designNumber) {
              ele.quantityRequired = payload.cartListItem.quantity;
            }
          });

          let data = {
            cart: cartdData,
            loader: false
          }

          dispatch(callActionGetCart(data));
          dispatch(callActionOnShowPopUp({ dialogMessage: res.message }));
        }
      },
      error: (err) => {
        if (err.code === 401) {
          dispatch(getToken());
        }
        else if (err.code === 402) {
          dispatch(OnLogoutUser());
          if (!window.location.pathname.includes('/sign-in')) {
            dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
            window.location.replace('/sign-in')
          }
        }
        else{
          dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please reload the page." }));
        }
        let data = {
          loader: false
        }
        dispatch(callActionGetCart(data));
        dispatch(getToken());
      },
    };

    API.updateQty(storeLocation, data, cb);
  };
};


export const deleteItem = (payload) => {
  return (dispatch, getState) => {
    let data = payload;
    let cartdData = getState().cart && getState().cart.cart;
    // added store location and passed to API
    let storeLocation = getState().loginDetails.storeLocation;

    dispatch(callActionGetCart({ loader: true }));

    let cb = {
      success: (res) => {
        if (res.code === 200 || res.code === 201) {
          
          dispatch(getCartData({
            id: getState().loginDetails.customer_details.phoneNumber,
            itemCount: true,
            
          }));
          cartdData = cartdData.filter(ele => {
            return ele.designNumber !== payload.designNumber
          });

          let data = {
            cart: cartdData,
            loader: false
          }

          dispatch(callActionGetCart(data));
          dispatch(callActionOnShowPopUp({ dialogMessage: res.message }));
        }
      },
      error: (err) => {
        if (err.code === 401) {
          dispatch(getToken());
        }
        else if (err.code === 402) {
          dispatch(OnLogoutUser());
          if (!window.location.pathname.includes('/sign-in')) {
            dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
            window.location.replace('/sign-in')
          }
        }
        else{
          dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please reload the page." }));
        }

        let data = {
          loader: false
        }
        dispatch(callActionGetCart(data));
        dispatch(getToken());
      },
    };

    API.deleteItem(storeLocation, data, cb);
  };
};


// added store location and passed to API
export const placeOrder = (payload) => {
  return (dispatch, getState) => {
    let data = payload;
    const { history } = payload;
    dispatch(callActionGetCart({ loader: true }));
    let storeLocation =  getState().loginDetails.storeLocation;

    let cb = {
      success: (res) => {
        if (res.code === 200 || res.code === 201) {
          // firebase.analytics().logEvent('place_order', {item_id: '123'});
          let data = {
            cart: [],
            cartCount: 0,
            loader: false
          }

          dispatch(callActionGetCart(data));
          dispatch(callActionOnShowPopUp({ dialogMessage: res.message }));
          history.push(`/thank-you?${res.result[0] && res.result[0].orderId}`);
        }
      },
      error: (err) => {
        if (err.code === 401) {
          dispatch(getToken());
          let data = {
            loader: false
          }
          dispatch(callActionGetCart(data));
        }
        else if (err.code === 402) {
          dispatch(OnLogoutUser());
          if (!window.location.pathname.includes('/sign-in')) {
            dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
            window.location.replace('/sign-in')
          }
          let data = {
            loader: false
          }
          dispatch(callActionGetCart(data));
        } else if (err.code === 400) {
            dispatch(callActionOnShowPopUp({ dialogMessage: err.message }));
            dispatch(getCartData({
              id: getState().loginDetails.customer_details.phoneNumber,
              itemCount: false
            }));
        }
        
        // dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please reload the page." }));
      },
    };

    API.placeOrder(storeLocation, data, cb);
  };
};
