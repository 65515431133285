import * as actionType from "../actionTypes";
import { updateObject } from "../utilityReducers";

const initialState = {

  category: [],
  subCategory: [],
  banner: [],
  loader: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_CATEGORY:
      return updateObject(state, action.payload);
    case actionType.GET_CATEGORY_BANNER:
      return updateObject(state, action.payload);

    default:
      return state;
  }
};

export default reducer;
