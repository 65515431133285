//redux
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
// Redux Persist
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import signUpReducer from "../reducers/signUpReducer";
import categoryReducer from "../reducers/categoryReducer";
import productReducer from '../reducers/productsReducer';
import cartReducer from '../reducers/cartReducer';
import orderReducer from '../reducers/orderReducer';

const AppRootReducer = combineReducers({
  loginDetails: signUpReducer,
  category: categoryReducer,
  product: productReducer,
  cart: cartReducer,
  order: orderReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT_USER") {
    storage.removeItem("persist:root");
    if (state !== null && state !== undefined) {
      Object.keys(state).map((s) => {
        if (s !== "global" && s !== "menu") {
          state[s] = undefined;
        }
      });
    }
    //window.location.reload();
  }
  return AppRootReducer(state, action);
};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);
