import { API } from "../../api/api";
import * as actionType from "../actionTypes";
import { callActionOnShowPopUp, getToken, OnLogoutUser } from './signUp';
const callActionGetCategory = (payload) => {
  return {
    type: actionType.GET_CATEGORY,
    payload: payload,
  };
};

const callActionGetCategoryBanner = (payload) => {
  return {
    type: actionType.GET_CATEGORY_BANNER,
    payload: payload,
  };
};

export const getCategory = (payload) => {
  return (dispatch, getState) => {
    let data = payload;
    dispatch(callActionGetCategory({ loader: true }));
    let storeLocation = getState().loginDetails.storeLocation;

    let cb = {
      success: (res) => {
        if (res.code === 200 || res.code === 201) {
          let data = payload.url_key === 'root' ? {
            category: res.result,
            loader: false
          } : {
              subCategory: res.result,
              loader: false
            }
          dispatch(callActionGetCategory(data));
          // toast(res.message)
        }
      },
      error: (err) => {
        let data = {
          loader: false
        }
        if (err.code === 401) {
          dispatch(getToken());
        }
        else if (err.code === 402) {
          dispatch(OnLogoutUser());
          if (!window.location.pathname.includes('/sign-in')) {
            dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
            window.location.replace('/sign-in')
          }
        }
        else {
          dispatch(callActionGetCategory(data));
          dispatch(getToken());
          dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please reload the page." }));
        }
      },
    };

    API.getCategory(storeLocation, data, cb);
  };
};

export const getCategoryBanner = (payload) => {
  return (dispatch, getState) => {
    let data = payload;
    dispatch(callActionGetCategoryBanner({ bannerloader: true }));
    let storeLocation = getState().loginDetails.storeLocation;

    let cb = {
      success: (res) => {
        if (res.code === 200 || res.code === 201) {
          let data = {
            bannerloader: false,
            banner: res.result
          }
          dispatch(callActionGetCategoryBanner(data));
          // toast(res.message)
        }
      },
      error: (err) => {
        let data = {
          bannerloader: false
        }
        if (err.code === 401) {
          dispatch(getToken());
        }
        else if (err.code === 402) {
          dispatch(OnLogoutUser());
          if (!window.location.pathname.includes('/sign-in')) {
            dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
            window.location.replace('/sign-in')
          }
        } else if (err.code === 404) {
          dispatch(callActionGetCategoryBanner(data));
        }
        else {
          dispatch(callActionGetCategoryBanner(data));
          dispatch(getToken());
          dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please reload the page." }));
        }
      },
    };

    API.getCategoryBanner(storeLocation, cb);
  };
}