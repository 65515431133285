import { API } from "../../api/api";
import * as actionType from "../actionTypes";
import { callActionOnShowPopUp, getToken, OnLogoutUser } from './signUp';

const callActionGetOrderDetails = (payload) => {
    return {
        type: actionType.GET_ORDER_DETAILS,
        payload: payload,
    };
};

export const getOrderDetails = (payload) => {
    return (dispatch, getState) => {
        let data = payload;
        let phone = getState().loginDetails && getState().loginDetails.customer_details && getState().loginDetails.customer_details.phoneNumber;
        let cb = {
            success: (res) => {
                if (res.code === 200 || res.code === 201) {
                    let data = {
                        order: res.result
                    }
                    dispatch(callActionGetOrderDetails(data));
                }
            },
            error: (err) => {
                if (err.code === 401) {
                    dispatch(getToken());
                }
                else if (err.code === 402){
                    dispatch(OnLogoutUser());
                    if(!window.location.pathname.includes('/sign-in')){
                      dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
                      window.location.replace('/sign-in')
                    }
                  }
                dispatch(callActionOnShowPopUp({ dialogMessage: err.message }));
            },
        };

        API.getOrderDetails(data, phone, cb);
    };
};


export const getOrderList = () => {
    return (dispatch, getState) => {
        let phone = getState().loginDetails && getState().loginDetails.customer_details && getState().loginDetails.customer_details.phoneNumber;
        // added store location and passed to API
        let storeLocation = getState().loginDetails.storeLocation;

        dispatch(callActionGetOrderDetails({loader: true}));
        let cb = {
            success: (res) => {
                if (res.code === 200 || res.code === 201) {
                    let data = {
                        orderList: res.result,
                        loader: false
                    }
                    dispatch(callActionGetOrderDetails(data));
                }
            },
            error: (err) => {
                if (err.code === 401) {
                    dispatch(getToken());
                }
                else if (err.code === 402){
                    dispatch(OnLogoutUser());
                    if(!window.location.pathname.includes('/sign-in')){
                      dispatch(callActionOnShowPopUp({ dialogMessage: "Your Session has Expired , Please Login again." }));
                      window.location.replace('/sign-in')
                    }
                  }
                  dispatch(callActionGetOrderDetails({loader: false}));
                  dispatch(callActionOnShowPopUp({ dialogMessage: "Something went wrong, please try again." }));
            },
        };

        API.getOrderList(storeLocation, phone, cb);
    };
};