import "bootstrap/dist/css/bootstrap.css";
import React, { Component } from "react";
import logo from '../../assets/logo2.png';
import './index.css';

class Loader extends Component {

  render() {
    return (<>
    <div className="loading">
        <img className="logo-loader" src={logo} alt={"logo"} />
    </div>
    </>
    );
  }
}

export default Loader;
