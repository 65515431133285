import React, { Component, Suspense } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import Welcome from "./common/welocmePage";
import AppRoutes from './routes';



class App extends Component {
  render() {

    return (
        <IntlProvider locale="en">
          <BrowserRouter>
            <div>
              <Suspense
                fallback={
                  <div>
                    <Welcome />
                  </div>
                }
              >
                <Switch>
                  <AppRoutes />
                </Switch>
              </Suspense>
            </div>
          </BrowserRouter>
        </IntlProvider>
    );
  }
}

export default App;
