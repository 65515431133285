import axios from "axios";
import {
  BASE_URL,
} from "../api/globalApi";
import { buildHeader } from "./helpers";
//App API's

const LOGIN_USER = { type: "GET", url: BASE_URL + "customer/login/" };
const GET_CATEGORY = { type: "GET", url: BASE_URL + "category/" };
const GET_CATEGORY_BANNER = { type: "GET", url: BASE_URL + "category/banners/" };
const REGISTER_USER = { type: "POST", url: BASE_URL + "registration/" };
const GET_OTP = { type: "GET", url: BASE_URL + "generateOtp/" };
const CHANGE_PASSWORD = { type: "PUT", url: BASE_URL + "customer/changePassword/" };
const GET_PRODUCTS_LIST = { type: "POST", url: BASE_URL + "inventory/items" };
const GET_CART = { type: "GET", url: BASE_URL + "cart/" };
const UPDATE_CART_QTY = { type: "PUT", url: BASE_URL + "cart/" }
const DELETE_ITEM = { type: "DELETE", url: BASE_URL + "cart/" }
const GET_PRODUCT_DATA = { type: "GET", url: BASE_URL + "inventory/item/" };
const ADD_TO_CART = { type: "POST", url: BASE_URL + "cart/" };
const ADD_TO_WISHLIST = { type: "POST", url: BASE_URL + "wishlist/" };
const GET_WISHLIST = { type: "GET", url: BASE_URL + "wishlist/" };
const DELETE_WISHLIST_ITEM = { type: "DELETE", url: BASE_URL + "wishlist/" }
const PLACE_ORDER = { type: "POST", url: BASE_URL + "order/" };
const GET_ORDER_DETAILS = { type: "GET", url: BASE_URL + "order/" };
const GET_ORDER_LIST = { type: "GET", url: BASE_URL + "order/phoneNumber/" };
const UPDATE_CUSTOMER = { type: "PUT", url: BASE_URL + "customer/changeDetails/" };
const UPDATE_PASSWORD = { type: "PUT", url: BASE_URL + "customer/updatePassword/" };
const DELETE_OTP = { type: "DELETE", url: BASE_URL + "otp/" }
// added to take care of multiple stores
const GET_STORE_NAMES = { type: "GET", url: BASE_URL + "store/names" }
// added to get about us details from database
const GET_ABOUT_US = { type: "GET", url: BASE_URL + "category/aboutUs/" }

export const API = {
  // getCartCount: (data, cb) => request(data, cb, GET_CART_COUNT),
  loginUser: (data, cb) => request({ password: data.password }, cb,
    { ...LOGIN_USER, url: LOGIN_USER.url + data.phoneNum }),

  registerUser: (data, cb) => request(data, cb,
    { ...REGISTER_USER, url: REGISTER_USER.url + data.phoneNum }),

  getProductList: (data, cb) => request(data, cb,
    { ...GET_PRODUCTS_LIST, url: GET_PRODUCTS_LIST.url }),

  // added store location
  getProductData: (storeLocation, data, cb) => request({}, cb,
    { ...GET_PRODUCT_DATA, url: GET_PRODUCT_DATA.url + data.id + `?storeLocation=${storeLocation}` }),

  getCategory: (storeLocation, data, cb) => request({}, cb,
    { ...GET_CATEGORY, url: GET_CATEGORY.url + data.url_key + `?storeLocation=${storeLocation}` }),

  getCategoryBanner: (storeLocation, cb) => request({}, cb,
    { ...GET_CATEGORY_BANNER, url: GET_CATEGORY_BANNER.url + `${storeLocation}` }),

  getOtp: (data, cb) => request(data, cb,
    { ...GET_OTP, url: GET_OTP.url + data.phoneNum }),

  deleteOtp: (data, cb) => request(data, cb,
    { ...DELETE_OTP, url: DELETE_OTP.url + data.phoneNum }),

  changePassword: (phone, data, cb) => request(data, cb,
    { ...CHANGE_PASSWORD, url: CHANGE_PASSWORD.url + phone }),

  // Get Cart Items Using Phone number
  // added store location
  getCart: (storeLocation, data, cb) => request({ itemCount: data.itemCount }, cb,
    { ...GET_CART, url: GET_CART.url + data.id + "?storeLocation=" + storeLocation }),

  // Update Cart Items quantity
  // added store location
  updateQty: (storeLocation, data, cb) => request({ cartListItem: data.cartListItem }, cb,
    { ...UPDATE_CART_QTY, url: UPDATE_CART_QTY.url + data.id + "?storeLocation=" + storeLocation }),

  // Delete Cart Items quantity
  // added store location
  deleteItem: (storeLocation, data, cb) => request({}, cb,
    { ...DELETE_ITEM, url: DELETE_ITEM.url + data.id + "?designNumber=" + data.designNumber + "&storeLocation=" + storeLocation }),

  // added store location
  // added store location
  addToCart: (storeLocation, phone, data, cb) => request(data, cb,
    { ...ADD_TO_CART, url: ADD_TO_CART.url + phone + "/?removeWishlist=" + data.cartListItem.removeWishlist + "&storeLocation=" + storeLocation }),

  // added store location
  addToWishList: (storeLocation, phone, data, cb) => request(data, cb,
    { ...ADD_TO_WISHLIST, url: ADD_TO_WISHLIST.url + phone + "?storeLocation=" + storeLocation }),

  // added store location
  getWishList: (storeLocation, phone, cb) => request({}, cb,
    { ...GET_WISHLIST, url: GET_WISHLIST.url + phone + "?storeLocation=" + storeLocation }),

  // added store location
  deleteWishListItem: (storeLocation, phone, data, cb) => request(data, cb,
    { ...DELETE_WISHLIST_ITEM, url: DELETE_WISHLIST_ITEM.url + `${phone}?designNumber=${data.designNumber}&storeLocation=${storeLocation}` }),

  // added store location to identify the store wise order
  placeOrder: (storeLocation, data, cb) => request({ "remarks": data.remarks, "deliveryInfo": data.deliveryInfo }, cb,
    { ...PLACE_ORDER, url: PLACE_ORDER.url + `${data.id}?storeLocation=${storeLocation}` }),

  getOrderDetails: (data, phone, cb) => request({}, cb,
    { ...GET_ORDER_DETAILS, url: GET_ORDER_DETAILS.url + `${data.orderId}?customerPhoneNumber=${phone}` }),

  // added store location
  getOrderList: (storeLocation, phone, cb) => request({}, cb,
    { ...GET_ORDER_LIST, url: GET_ORDER_LIST.url + phone + "?storeLocation=" + storeLocation }),

  updateCustomer: (phone, password, data, cb) => request(data, cb,
    { ...UPDATE_CUSTOMER, url: UPDATE_CUSTOMER.url + phone + "?password=" + password }),

  updatePassword: (phone, data, cb) => request(data, cb,
    { ...UPDATE_PASSWORD, url: UPDATE_PASSWORD.url + phone }),

  // added to get the store names from database
  getStoreNames: (data, cb) => request(data, cb,
    { ...GET_STORE_NAMES, url: GET_STORE_NAMES.url }),

  // added to get the about us details from database
  getAboutUs: (storeLocation, data, cb) => request(data, cb,
    { ...GET_ABOUT_US, url: GET_ABOUT_US.url + storeLocation }),

};

async function request(
  requestData,
  cb,
  featureURL,
  secureRequest = buildHeader(),
  urlData = ""
) {
  const url = featureURL.dynamic
    ? featureURL.url + "/" + requestData.storeId
    : featureURL.url;
  try {
    let response;
    if (featureURL.type === "GET") {
      response = await axios.get(url, {
        headers: secureRequest,
        params: requestData,
      });
    } else if ("POST|PATCH|PUT".includes(featureURL.type)) {
      response = await axios[featureURL.type.toLocaleLowerCase()](
        url,
        requestData,
        {
          headers: secureRequest,
        }
      );
    } else if ("DELETE".includes(featureURL.type)) {
      response = await axios.create({ headers: secureRequest }).delete(url);
    }
    // if (!live) {
    // 	console.groupCollapsed('API RESPONSE');
    // 	console.groupEnd();
    // }
    if (cb.complete) cb.complete();
    if (response.status === 200 || response.status === 201) {
      cb.success(response.data);
    } else {
      cb.error(response.data);
    }
  } catch (error) {
    if (cb.complete) cb.complete();
    if (error.response) {
      cb.error(error.response.data);
    } else {
      cb.error(error);
    }
  }
}

