export {
  loadingSpinner,
} from "./globals";
// added export for getStoreNamesData
// added export for getAboutUsInfo
export {
  login,
  register,
  getOtp,
  changePassword,
  logout,
  hideOtp,
  LoginLoadingFalse,
  showPopup,
  hidePopup,
  updateCustomer,
  updatePassword,
  removePleaseWait,
  changeLocation,
  deleteOtp,
  getStoreNamesData,
  getAboutUsInfo,
} from "./signUp";

export {
  getCategory,
  getCategoryBanner
} from './category';

export {
  getProductList,
  getProductData,
  addToCart,
  addToWishList,
  getWishList,
  deleteWishListItem,
  falseLoader
} from './products';

export {
  getCartData,
  updateCartQyt,
  deleteItem,
  placeOrder
} from './cart';

export {
  getOrderDetails,
  getOrderList,
} from './order'
