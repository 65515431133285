import * as actionType from "../actionTypes";
import { updateObject } from "../utilityReducers";

const initialState = {
    cart: [],
    loader: false,
    cartCount: 0
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_CART:
      return updateObject(state, action.payload);
    case actionType.UPDATE_ITEM_QTY:
        return updateObject(state, action.payload);

    default:
      return state;
  }
};

export default reducer;