import * as actionType from "../actionTypes";
import { updateObject } from "../utilityReducers";

// Removed the value of store location in initial state
const initialState = {
  productList: [],
  filters: [],
  appliedFilter: [],
  isLastPage: false,
  loader: false,
  productData: {},
  addToCartLoader: false,
  storeLocation: "",
  plploader:false,
  plpcat: ""
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_PRODUCTS_LIST:
      return updateObject(state, action.payload);
    case actionType.GET_PRODUCT_DATA:
      return updateObject(state, action.payload);
    case actionType.ADD_TO_CART:
      return updateObject(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
