// export const live = true;
export const live = process.env.NODE_ENV === "production";

export let active_server = "local";

if (window.location.host.includes("qa")) {
  active_server = "qa";
}

export const ROOT_URL = {
  local: "https://asia-south1-karma-prints.cloudfunctions.net/",
};


export const BASE_URL = `${ROOT_URL[active_server]}`;
export const API_TOKEN = "";