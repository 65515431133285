import * as actionType from "../actionTypes";
import { updateObject } from "../utilityReducers";

//Removed the value of store location in initial state
// added aboutUsInfo object to maintain initial state
const initialState = {
  customer_details: {},
  isUserLoggedIn: false,
  loginMessage: null,
  loginErrorMessage: null,
  errorMessage: null,
  loading: false,
  idToken: '',
  storeLocation: "",
  registerMessage: null,
  registerErrorMessage: null,
  registerDetails: {},
  otpFetched: false,
  otpRecTimeMilli: null,
  otpPayload: null,
  dialogState: false,
  dialogMessage: '',
  dialogTimer: false,
  storeNames: [],
  aboutUsInfo: {},

};

// added cases for GetStoreNames and GetAboutUsInfo

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.LOGIN:
      return updateObject(state, action.payload);
    case actionType.REGISTER:
      return updateObject(state, action.payload);
    case actionType.GET_OTP:
      return updateObject(state, action.payload);
    case actionType.CHANGE_PASSWORD:
      return updateObject(state, action.payload);
    case actionType.UPDATE_CUSTOMER:
      return updateObject(state, action.payload);
    case actionType.UPDATE_PASSWORD:
      return updateObject(state, action.payload);
    case actionType.SHOW_POPUP:
      return updateObject(state, { ...action.payload, dialogState: true });
    case actionType.CLOSE_POPUP:
      return updateObject(state, { dialogState: false, dialogMessage: "", dialogTimer: false });
    case actionType.LOGOUT:
      return updateObject(state, {
        isUserLoggedIn: false, customer_details: {}
      });
    case actionType.HIDE_OTP:
      return updateObject(state, {
        otpFetched: false
      });
    case actionType.GET_STORE_NAMES:
      return updateObject(state, action.payload);
    case actionType.GET_ABOUT_US:
      return updateObject(state, action.payload)

    default:
      return state;
  }
};

export default reducer;
